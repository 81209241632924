import { Component, Host, h, Prop, Element, Listen, State } from '@stencil/core';
import { formatedPrice, debounce, isOnIframe } from '../../../../utils/utils';
import { OptionSelected } from '../../../ptz-dropdown-option/ptz-dropdown-option.types';
import { PTZHeaderSearchTypes } from './types/ptz-header-search.types';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderConfig } from '../../types/ptz-header.enums';
import { PTZCardTagConfig } from '../../../ptz-card-tag/types/ptz-card-tag.enums';

@Component({
  tag: `ptz-header-search`,
  styleUrl: `ptz-header-search.scss`,
  shadow: false,
})
export class PTZHeaderSearch {
  /** Define a url base do ambiente */
  @Prop() baseUrl: string = '';

  /** Valor do input da busca */
  @Prop() value: string = '';

  /** Deve ou não mostrar o dropdown da busca */
  @Prop({ mutable: true }) showDropdown?: boolean = false;

  /** Lista de recomendações */
  @Prop() recommendedList?: PTZHeaderSearchTypes.RecommendedList;

  /** Método de redirecionamento da busca */
  @Prop() redirectPageHandler: (value: string) => void;

  /** Método de mudança da busca */
  @Prop() changeValueHandler: PTZHeaderTypes.ChangeSearchValueHandler;

  /** Método de clique da busca */
  @Prop() clickHandler: PTZHeaderTypes.ClickSearchHandler;

  /** Método de submit da busca */
  @Prop() submitSearchValueHandler: (event: Event) => void;

  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

  /** A versão da tela é mobile ou desktop */
  @Prop() isMobile: boolean;

  /** Flag para blackFriday */
  @Prop() blackFriday: boolean;

  @State() isError: boolean = false;

  @Element() el: HTMLElement;

  private setIsError = (isError: boolean) => {
    this.isError = isError;
  };

  @Listen('click', { target: 'window' })
  handlerClick(event) {
    const path = event.composedPath();
     if (!path.includes(this.el.querySelector('form'))) {
      this.showDropdown = false;
    } else {
      this.showDropdown = true;
    }
  }

  @Listen('optionSelected')
  optionSelectedHandler(event: CustomEvent<OptionSelected>) {
    this.redirectPageHandler(event.detail.value);
  }

  private onLoad = () => {
    const iconSearch = document.querySelector('#headerSearch + .ptz-input-icon');
    iconSearch?.addEventListener('click', event => this.submitSearchValueHandler(event));
  };

  componentDidLoad() {
    this.onLoad();
  }

  private searchRecommendationTracking(searchText: string, recommendation_type: string) {
    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.SearchRecommendationClicked, {
      recommendation: searchText,
      recommendation_type,
    });
  }

  private recommendedProductTracking(product: PTZHeaderSearchTypes.ProductsList, position: string, kind: PTZHeaderTypes.AnalyticsTrackingKind) {
    this.analyticsTrackingHandler(kind, {
      link_url: `${this.baseUrl}/produto/${product.url}`,
      image_url: `${product.imgUrl}`,
      query: `${this.value}`,
      gallery_name: 'Produtos sugeridos',
      product_discount: `${product.discount}` || null,
      product_name: `${product.name}` || null,
      product_installments: null,
      product_position: position,
      product_price: `${product.realPrice}`,
      product_sku: `${product.sku}`,
    });
  }

  componentDidUpdate() {
    this.recommendedList?.products.forEach((product, index) => {
      this.recommendedProductTracking(product, `${index+1}`, PTZHeaderConfig.AnalyticsTrackingKind.RecommendedProductViewed);
    });
  }

  render() {
    const hasProducts = this.recommendedList?.products && this.recommendedList?.products.length > 0;
    const historyQuantityMobileWithProducts = 1;
    const historyQuantityMobileWithoutProducts = 3;
    const historyQuantityDesktop = 4;
    const historyQuantityMobile = hasProducts ? historyQuantityMobileWithProducts : historyQuantityMobileWithoutProducts;
    const historyList = this.isMobile ? this.recommendedList?.history.slice(0, historyQuantityMobile) : this.recommendedList?.history.slice(0, historyQuantityDesktop);

    const handleChange = debounce((event) => this.changeValueHandler(event, this.setIsError), 500) as PTZHeaderTypes.DebouncedFunction;

    return (
      <Host>
        {this.showDropdown && <div class="ptz-header-search-overlay" />}
        <form method="GET" class={`ptz-header-search ${this.blackFriday ? 'black-friday' : ''}`} onSubmit={event => this.submitSearchValueHandler(event)}>
          <ptz-text-field
            size="md"
            brand="ptz"
            kind="boxed"
            state="resting"
            control="normal"
            icon-name="search"
            icon-align="right"
            icon-variant="line"
            autocomplete="off"
            input-id="headerSearch"
            placeholder="O que seu pet precisa?"
            value={this.value}
            onClick={event => {
              this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.SearchbarClicked);
              this.clickHandler(event, this.setIsError);
            }}
            onInput={event => handleChange(event)}
          />
          {this.showDropdown && this.isError && (
            <div class="ptz-header-search-dropdown ptz-header-search-dropdown-error">
              <ptz-header-error show={this.isError} />
            </div>
          )}
          {this.showDropdown && this.recommendedList?.terms.length > 0 && (
            <div class="ptz-header-search-dropdown">
              <div class={`ptz-header-search-dropdown-section ${hasProducts ? 'ptz-header-search-terms-and-history-recommended' : ''}`}>
                {historyList.length > 0 && (
                  <div>
                    {!hasProducts && <span class="ptz-header-search-dropdown-item-title">Histórico de busca</span>}
                    {historyList.map(item => (
                      <ptz-dropdown-option
                        key={item.id}
                        data-id={item.id}
                        icon-name="history"
                        icon-variant="line"
                        supportIconName="petz-cancel"
                        supportIconVariant="line"
                        value={item.searchText}
                        onClick={() => this.searchRecommendationTracking(item.searchText, 'Histórico de busca')}
                      ></ptz-dropdown-option>
                    ))}
                  </div>
                )}
                <div>
                  {!hasProducts && <span class="ptz-header-search-dropdown-item-title">Termos mais buscados</span>}
                  {this.recommendedList?.terms.map(item => (
                    <ptz-dropdown-option
                      key={item.searchText}
                      icon-name="search"
                      icon-variant="line"
                      value={item.searchText}
                      onClick={() => this.searchRecommendationTracking(item.searchText, 'Termos mais buscados')}
                    ></ptz-dropdown-option>
                  ))}
                </div>
              </div>
              {hasProducts && (
                <div class="ptz-header-search-dropdown-section">
                  <span class="ptz-header-search-dropdown-item-title">Produtos sugeridos</span>
                  <div class="ptz-header-search-product-list">
                    {this.recommendedList?.products.map((product, index) => (
                      <a
                        key={product.sku}
                        class="ptz-header-search-product-item"
                        href={`${this.baseUrl}/produto/${product.url}`}
                        { ...(isOnIframe() ? { target: "_parent" } : {}) }
                        onClick={() => this.recommendedProductTracking(product, `${index+1}`, PTZHeaderConfig.AnalyticsTrackingKind.RecommendedProductClicked)}
                      >
                        <ptz-card kind="product" size="fill" label={product.name} outlined={false} orientation="horizontal" showPrice={true} showHeartIcon={false}>
                          <ptz-card-image slot="card-image" url={product.imgUrl}>
                            {product.discount ?
                              <ptz-card-tag
                                kind={this.blackFriday ? PTZCardTagConfig.Kind.Blackfriday : PTZCardTagConfig.Kind.Default}
                                label={`${product.discount}% OFF`} />
                              : ''}
                          </ptz-card-image>
                          <ptz-card-price-section
                            slot="card-price"
                            price={formatedPrice(product.realPrice)}
                            older-price={product.showFullPrice ? formatedPrice(product.fullPrice) : ''}
                          />
                          {!product.hideSubscriberPrice &&
                            <ptz-card-subscription-section
                              slot="card-subscription"
                              showBuyIcon={false}
                              subscription-text="para assinantes"
                              subscription-price={formatedPrice(product.subscriberPrice)}
                            />}
                        </ptz-card>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
      </Host>
    );
  }
}
